import { Box, Button, Divider, Grid, IconButton, LinearProgress, Menu, MenuItem, Paper, Stack, Typography } from "@mui/material";
import * as React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { LoadingButton } from "@mui/lab";
import RegenerateDialog from "./RegenerateDialog";
import { Download } from "@mui/icons-material";


export default function ArtifactGuidePane({
    artifact,
    onGenerate,
    onDownloadFile, // with parameter 'docx' or 'pdf'
    regenerateInstructions,
    setRegenerateInstructions,
    regenerateAll
}) {



    const isGenerating = () => {
        return (artifact && artifact.generation_state && artifact.generation_state === 'GENERATE_ALL_STARTED');
    };

    const [progress, setProgress] = React.useState(0);
    const [regenDialogOpen, setRegenDialogOpen] = React.useState(false);
    const [downloadMenuAnchorEl, setDownloadMenuAnchorEl] = React.useState(null);
    const downloadMenuOpen = Boolean(downloadMenuAnchorEl);

    const downloadMenuClick = (event) => {
        setDownloadMenuAnchorEl(event.currentTarget);
    };

    const handleDownloadMenuClose = () => {
        setDownloadMenuAnchorEl(null);
    };


    React.useEffect(() => {
        if (artifact && artifact.generation_state && artifact.generation_state === 'GENERATE_ALL_STARTED') {
            console.log('setting progress!');
            setProgress(artifact.workflow_progress / artifact.workflow_count * 100);
        }
        else {
            console.log('resetting progress!');
            setProgress(0);
        }


    }, [artifact]);

    return (
        <Paper sx={{
            borderRadius: '10px',
            borderColor: 'white',
            padding: '10px',
            paddingLeft: '100px',
            paddingRight: '100px',
            paddingBottom: '15px',
            marginBottom: '10px'
        }}>
            <Grid container>
                <Grid item xs={4}>
                    <Box>
                        <Typography>
                            <h3>Generate</h3>
                        </Typography>
                        <Button
                            disabled={!artifact || isGenerating()}
                            onClick={onGenerate}
                            startIcon={<AutoAwesomeIcon />}>
                            Generate Document
                        </Button>

                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <Typography>
                            <h3>Iterate</h3>
                        </Typography>
                        <Button
                            disabled={!artifact || isGenerating()}
                            onClick={() => setRegenDialogOpen(true)}
                            startIcon={<AutoAwesomeIcon />}>Edit with AI</Button>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <h3>Download</h3>
                        <IconButton color="primary" onClick={downloadMenuClick}><Download /></IconButton>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {isGenerating() &&
                        <Box>
                            <Typography>Generating{artifact.workflow_count <= 0 ? "..." : ` section ${artifact.workflow_progress + 1} of ${artifact.workflow_count}...`}</Typography>
                            <LinearProgress variant="determinate" value={progress} />
                        </Box>
                    }
                </Grid>
            </Grid>


            <RegenerateDialog
                type='document'
                open={regenDialogOpen}
                setRegenDialogOpen={setRegenDialogOpen}
                regenerateInstructions={regenerateInstructions}
                setRegenerateInstructions={setRegenerateInstructions}
                action={regenerateAll}
            />

            <Menu
                id="basic-menu"
                anchorEl={downloadMenuAnchorEl}
                open={downloadMenuOpen}
                onClose={handleDownloadMenuClose}
            >
                <MenuItem onClick={() => { onDownloadFile('docx'); setDownloadMenuAnchorEl(null); }}>Download Word</MenuItem>
                <MenuItem onClick={() => { onDownloadFile('pdf'); setDownloadMenuAnchorEl(null); }}>Download PDF</MenuItem>
                <MenuItem onClick={() => { onDownloadFile('md'); setDownloadMenuAnchorEl(null); }}>Download Markdown</MenuItem>
                <MenuItem onClick={() => { onDownloadFile('html'); setDownloadMenuAnchorEl(null); }}>Download HTML</MenuItem>

            </Menu>


        </Paper >
    );
}